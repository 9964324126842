/**
 * A Screener is a container for Slides with logic for conducting a survey.
 * Supported props: config, sitedata, localizerfn
 */
import React from 'react';
import useScreener from './useScreener';
import { mapFields } from '../util/datatools';
import { getCookie } from '../util/pagetools';
import { sendToFB, sendToGA, sendToGA_done } from '../util/gatools';

export default function Screener(props) {
  const { config, sitedata, localizerfn } = props;
  if (!config || Object.keys(config).length===0) return <section id="screener">No screener config</section>;
  const screener = useScreener(config, sitedata, localizerfn, collector, handleSlide);

  /**
   * Changes active slide based on user input.
   * @param object - a question configuration object
   * @param boolean - a boolean user response
   */
  function handleSlide(question, slideReply) {
    const canProceed = question.proceed(slideReply);
    sendToGA(question.id, slideReply);
    if (canProceed) {
      screener.nextSlide();
      if (question.isLast()) {
        sendToFB(true);
        sendToGA_done(true);
        screener.setQualified(true);
      }
    } else {
      // Disqualification results in moving to the fail page
      sendToFB(false);
      sendToGA_done(false);
      screener.nextSlide(screener.config().fail);
    }
  }

  /**
   * Sets the candidate data from a form.
   * @param object - field values from a form
   */
  function collector(obj) {
    const isQualified = screener.isQ();
    const mapped = mapFields(obj, (sitedata.forms ? sitedata.forms.candidate.formfields : {}));
    screener.appendToPayload(Object.assign({
      PlanId: config.pid,
      Language: sitedata.locale,
      Url: sitedata.siteurl,
      IsEligible: (isQualified ? 1 : 0),
      SRCode: (isQualified ? 'PD2' : 'DQ201'),
      Data1: getCookie('UTM_INFO')
    }, mapped));
    // Send the candidate data and navigate to thank you page
    screener.transmitData();
    screener.navigateToConclusion();
  }

  return (
    <section id="screener" className="screener-section">
      <div className="screener">
        {screener.render()}
      </div>
    </section>
  );
};
